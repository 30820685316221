import React, { useCallback, useEffect, useRef, useState } from "react"

import { observer } from "mobx-react"

import { useRequest } from "@dnr/hooks"
import { useTranslate } from "@dnr/localization"
import { lookupService } from "@dnr/data/services"

import { topicService } from "../services"
import { TopicStore } from "../stores"
import { TopicMessageDisplay } from "./TopicMessageDisplay"
import { NewTopicMessageEditor } from "./NewTopicMessageEditor"

interface TopicMessagingViewProps {
    ticketStatusId?: number
    ticketId?: string
    currentUserId: string
    messages?: (state: boolean) => void
    topicStore: TopicStore
    env: "backoffice" | "portal"
    type: "ticket" | "shippingrequest",
    topicCreatedById: string
}

export const TopicMessagingView: React.FC<TopicMessagingViewProps> = observer((props) => {
    const { t } = useTranslate()
    const messagesEndRef = useRef<HTMLDivElement>(null)

    const topicStatus = useCallback((id?: number) => {
        if (id) {
            return lookupService.getTopicStatusId()[id].abrv
        }
        return ""
    }, [])

    const { loading } = useRequest(
        async (options) => {
            if (!props.ticketId) {
                return
            }

            const element = await topicService.getLatestMessages(
                props.ticketId,
                {
                    abortController: options.abortController,
                },
                {
                    includeUserInfo: true,
                }
            )

            if (props.messages) {
                if (element.result.length > 0) {
                    props.messages(true)
                }
            }

            props.topicStore.setMessages(element.result, props.currentUserId)
        },
        [props.ticketId]
    )

    useEffect(() => {
        if (messagesEndRef.current) messagesEndRef.current.scrollIntoView()
    }, [props.topicStore?.messages, props.topicStore?.messageCount])

    return !props.topicStore || loading ? (
        <div className="c-loader">Loading...</div>
    ) : (
        <>
            <div className="u-display--separated">
                <p className="c-section__title--sml u-mb--sml--3">{t.common("GENERAL.CHAT_HISTORY")}</p>
                <div className="u-display--flex u-display--flex--ai--center  u-mb--sml--2">
                    <i className="u-icon u-icon--base u-icon--messages"></i>
                    <p className="u-type--sml u-type--wgt--medium u-type--color--title u-ml--sml--1">
                        {props.topicStore.messageCount}
                    </p>
                </div>
            </div>

            <div className="c-cards c-cards--base lrg-height">
                {props.topicStore.messages.map((msg) => (
                    <div
                        key={msg.message.id}
                        className={`u-mb--sml--2 c-message__container ${msg.isCurrentUsersMessage ? "c-message__container--right" : null
                            }`}
                    >
                        <TopicMessageDisplay store={msg} />
                    </div>
                ))}
                {topicStatus(props.ticketStatusId) !== "closed" && props.ticketId ? (
                    <NewTopicMessageEditor currentTopicId={props.ticketId} store={props.topicStore} env={props.env} type={props.type} topicCreatedById={props.topicCreatedById} />
                ) : null}
                <div ref={messagesEndRef} />
            </div>
        </>
    )
})
