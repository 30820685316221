import React, { useState } from "react"

import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { observer } from "mobx-react"

import { helperService } from "@dnr/data/services"
import { FormContainer, FormSubmit, TextAreaInput } from "@dnr/ui/forms"
import { useTranslate } from "@dnr/localization"
import { ButtonGroup, SecondaryButton } from "@dnr/ui/buttons"
import { useSubmitter } from "@dnr/hooks"
import { ApiResponseModel } from "@dnr/data/models"
import { enqueueSnackbar } from "notistack"

import { topicService } from "../services"
import { TopicStore } from "../stores"
import { TopicMessage } from "../models"
import { productPropertiesService } from "@dnr/features/portal/products"

interface NewTopicMessageEditorProps {
    store: TopicStore
    currentTopicId: string
    env: "backoffice" | "portal"
    type: "ticket" | "shippingrequest",
    topicCreatedById: string
}

interface NewMessageForm {
    content: string
    file?: File
    isFileUploaded?: boolean
}

const schema = yup.object({
    content: yup.string().when("isFileUploaded", {
        is: true,
        then: yup.string(),
        otherwise: yup.string().required("GENERAL.REQUIRED").min(3).trim(),
    }),
    isFileUploaded: yup.boolean(),
})

export const NewTopicMessageEditor: React.FC<NewTopicMessageEditorProps> = observer((props) => {
    const [file, setFile] = useState<File>()
    const [isSendEmailBtnDisabled, setIsSendEmailBtnDisabled] = useState(false);
    const { fileNameFormatter, handleDrag, handleDrop } = helperService
    const form = useForm<NewMessageForm>({
        resolver: yupResolver(schema),
    })

    const { t } = useTranslate()

    const { onSubmit } = useSubmitter<NewMessageForm, ApiResponseModel<TopicMessage>>({
        requestCallback: (data) => {
            const formData = new FormData()
            const reqObj = {
                content: data.content.replace(/\r\n|\r|\n/g, "<br />"),
                userId: props.store.currentUserId,
                topicId: props.currentTopicId,
                messageType: props.type
            }
            if (file) {
                formData.append("File", file, fileNameFormatter(file.name))
            }

            formData.append("Request", JSON.stringify(reqObj))
            return topicService.sendNewMessage(formData)
        },
        onSuccess: (response) => {
            form.reset()
            props.store.addNewMessage(response.result)
            props.store.setIsMessageSent()
            setFile(undefined)
            form.setValue("isFileUploaded", false)
            return true
        },
        errMsg: t.error("GENERAL.FILE_UPLOAD_FAILED"),
        logErrMsg: "Error on submitting new message",
    })

    const sendNewResponseToEmail = async () => {
        try {
            setIsSendEmailBtnDisabled(true);
            await topicService.sendLatestResponse(
                {
                    topicId: props.currentTopicId,
                    messageType: props.type,
                    topicCreatedById: props.topicCreatedById
                }
            );
            enqueueSnackbar({
                variant: "success",
                message: t.common("DEAL.EMAILS_SENDING_SUCCESS"),
            })
        } catch (error) {
            enqueueSnackbar({
                variant: "error",
                message: t.common("GENERAL.EMAILS_SENDING_FAILED"),
            })
        } finally {
            setIsSendEmailBtnDisabled(false);
        }
    }


    return (
        <FormContainer<NewMessageForm> form={form} onSubmit={onSubmit} className="u-display--width--full">
            <div className="u-display--flex u-display--flex--fd--column">
                {/* <hr className="c-separator--color-light u-mt--sml--4 u-mb--sml--4" /> */}
                <div
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={(e) =>
                        handleDrop(e, (files) => {
                            setFile(files[0])
                            form.setValue("isFileUploaded", true)
                        })
                    }
                    className="u-display--width--full u-mt--sml--4"
                >
                    <TextAreaInput<NewMessageForm>
                        name="content"
                        fileUpload={{
                            clearFile: !file,
                            displayText: t.common("GENERAL.OR_UPLOAD_FILE"),
                            onFileSelected: (file) => {
                                setFile(file)
                                form.setValue("isFileUploaded", true)
                            },
                        }}
                        label={t.common("GENERAL.REPLY")}
                        placeholder={t.common("GENERAL.START_TYPING_OR_DRAG_FILE")}
                        disabled={file !== undefined}
                        inputsize="lrg"
                        required={!file}
                    />
                    {file ? (
                        <div className="u-display--flex u-display--flex--ai--center u-display--flex--gap--xsml">
                            <i className="u-icon u-icon--base u-icon--attachment" />
                            <p>{file.name}</p>
                            <i
                                className="u-icon u-icon--sml u-icon--close--dark u-cursor--pointer"
                                onClick={() => {
                                    setFile(undefined)
                                    form.setValue("isFileUploaded", false)
                                }}
                            ></i>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
                <hr className="c-separator--color-light u-mt--sml--4 u-mb--sml--4" />
                <ButtonGroup style="separated">
                    <SecondaryButton type="button" onClick={() => form.reset()} size="base">
                        {t.common("GENERAL.DISCARD")}
                    </SecondaryButton>
                    <div className="u-mx--sml"> {/* Spacer to separate buttons */}
                        {props.env === "backoffice" && (
                            <SecondaryButton type="button" onClick={sendNewResponseToEmail} size="base" disabled={isSendEmailBtnDisabled}>
                                {t.common("Send latest response to dealer")}
                            </SecondaryButton>
                        )}
                    </div>
                    <FormSubmit btnSize="base" displayText="GENERAL.SEND" />
                </ButtonGroup>
            </div>
        </FormContainer>
    )
})
